import React from "react";
import { object } from "prop-types";
import { Layout, Post } from "../components/";

const FeedPage = ({ pageContext }) => {
    let i = 0;
    return (
        <>
            <Layout>
                <div className="mx-auto my-10 mt-60 sm:mt-40 prose lg:prose-lg max-w-100ch">
                    <h1>{pageContext.feedTitle}</h1>
                    <div className="flex flex-col">
                        {Object.values(pageContext.feed).length === 0 ? (
                            <span className="text-xl font-bold">
                                Jeszcze nic tu nie ma, zapraszamy wkrótce
                                {" :)"}
                            </span>
                        ) : (
                            pageContext.feed.map(article => (
                                <Post key={i++} data={article} />
                            ))
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
};

FeedPage.propTypes = {
    pageContext: object,
};

export default FeedPage;
